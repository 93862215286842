import api from "..";

const upsertHubSpot = async (request) => {
  // Make Request, Include Response in Variable
  const response = await api.put("/hubspot", request);

  return response.data;
};

const lookupHubSpot = async (email) => {
  // Make Request, Include Response in Variable
  const response = await api.get("/hubspot?email=" + email);

  return response.data;
}

const hubSpotService = {
  upsertHubSpot,
  lookupHubSpot,
};

export default hubSpotService;
